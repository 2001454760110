.AudioTranscriptionGuidanceModal__doubts {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    gap: 30px;

    strong {
      color: $red; }

    div > p {
      line-height: 22px; }

    div > p:first-of-type {
      margin-bottom: 5px; }

    &__last {
      display: flex;
      flex-direction: column;
      p {
        margin-bottom: 30px; } } }

.Modal {
  align-items: flex-start;
  display: flex;
  height: 100%;
  justify-content: center;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 200;

  &__backdrop {
    animation: openModal 0.5s ease;
    background-color: $grey-five;
    height: 100%;
    opacity: 0.9;
    position: absolute;
    width: 100%;
    z-index: 1; }

  &__container {
    align-items: center;
    background-color: $white;
    display: flex;
    flex-direction: column;
    height: auto;
    justify-content: center;
    padding: 30px 90px;
    position: relative;
    width: 100%;
    z-index: 2;
    margin-top: 60px;
    border-radius: 30px;
    overflow: auto;

    @include desktop-media {
      width: 536px;
      max-height: 90vh; }

    @include mobile-media {
      padding: 40px 15px;
      height: 100%;
      justify-content: flex-start; }

    @include tablet-media {
      padding: 30px 15px;
      height: 100%;
      justify-content: flex-start; } } }

/** ANIMATIONS **/
// Fade-in
@keyframes openModal {
  0% {
    opacity: 0; }
  100% {
    opacity: 0.9; } }


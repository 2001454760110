.AudioTranscriptionGuidance {
  width: 300px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 10px;

    &__title {
      display: flex;
      align-items: center;
      gap: 5px; }

    &__badge {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 41px;
      height: 17px;
      border: 1px solid $green;
      border-radius: 5px;
      p {
        color: $green;
        font-size: 1rem;
        font-weight: 500;
        height: inherit;
        letter-spacing: 2px;
        padding-left: 2px; } }

    &__helper {
      &:hover {
        cursor: pointer;
        filter: brightness(1.2); } } }

  &__description {
    font-size: $font-xs;
    color: $grey-six;
    text-align: left;
    margin-top: $size-m;
    margin-bottom: $size-m; }

  &__hyperlink {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 30px; } }

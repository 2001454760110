.AudioTranscriptionGuidanceModal {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 340px;
  height: auto;

  &__closeButton {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
    background: initial;
    border: initial;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;

    &:hover {
      background: $grey-two;
      transition: background 0.2s; } }

  &__tabs {
    width: 100%;
    margin-top: 30px; }

  &__content {
    width: 100%;
    max-height: 60vh;
    overflow-y: auto;
    padding-right: 10px;

    scrollbar-width: none; }

  &__confirm {
    margin-top: 40px;
    border-radius: 30px !important;
    width: 330px;

    @include mobile-media {
      margin-bottom: 40px; } } }
